.tableContainer {
    -webkit-overflow-scrolling: touch;
}

table {
    max-height: 50vh;
    min-height: 19rem;
    overflow-y: scroll;
    overflow-x: scroll;
    display: block;
}

table > thead {
    vertical-align: middle;
    border-bottom: 2px solid #ddd;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
}