input[type="button"]:disabled,
input[type="checkbox"]:disabled,
input[type="radio"]:disabled,
button:disabled {
    cursor: not-allowed !important;
    opacity: .50
}

input[type="text"]:disabled,
textarea:disabled {
    cursor: not-allowed !important;
}

[type="button"] {
    -webkit-appearance: none !important;
}

.animate-right {
    position: relative;
    animation: animateright 0.3s
}

@keyframes animateright {
    from {
        right: -50px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

// removes the spinner circle for axios loading bar
#nprogress .spinner-icon {
    display: none;
}

html {
    -webkit-print-color-adjust: exact;
  }

.nice-scroll {
    &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
    }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border: 0 none #fff;
        border-radius: 0;
    }
    &::-webkit-scrollbar-track {
        background: #e1e1e1;
        border: 0 none #fff;
        border-radius: 0;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}