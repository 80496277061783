:root {
    --brand-color-1: #00816d;
}

$red: #aa0000;

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

