@import "../../assets/scss/base";

.base-layout {
    .nav {
        background: $red;
    }
}
.navbar {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    height: 4rem;
    padding-left: 2em;
    padding-right: 2em;
    align-items: center;
    z-index: 10;
    background-color: white;
    width: 100%;
    &-items-container {
        display: flex;
        position: fixed;
    }
}

.sidebar {
    top: 0;
    min-width: 15rem;
    //z-index: 3000;
    padding-right: 1rem;
    padding-top: 6rem;
    height: 100%;
}


